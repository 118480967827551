<template>
  <div class="tabs-popup">
    <div class="tabs-popup__inner">
      <div class="tabs-popup__items">
        <slot name="item-tab">

        </slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TabsInPopup"
  }
</script>

<style scoped lang="scss">
  @import '../../../../scss/colors';
  @import '../../../../scss/mixins/mixins';

  .tabs-popup {

    &__inner {

    }

    &__items {
      display: flex;
      overflow-x: auto;
      position: relative;

      &:before{
        content: "";
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        height: 2px;
        margin-bottom: 2px;
        background: #F8F4EE;
        border-radius: 9px;
      }

      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        border: 1px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        margin-right: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: $borderBrown;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $borderBrown;
        opacity: .5;
      }
    }

    &__item {
      position: relative;
      min-width: 150px;
      text-align: center;
      padding: 0 20px 14px;
      cursor: pointer;

      font-size: 18px;
      line-height: 21px;
      color: $borderBrown;
      transition: 0.3s;

      @include for-1600{
        padding: 0 15px 14px;
        min-width: fit-content;
        white-space: nowrap;
      }


      @include for-768{
        font-size: 16px;
      }

      &:after{
        content: "";
        display: block;
        height: 4px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: $brown;
        opacity: 0;
        box-shadow: 0 4px 4px #D0C0AD;
        transition: 0.3s;
      }

      &:hover,
      &.active{
        color: $brown;

        &.content-tabs__item-small{
          font-weight: 700;
        }
      }

      &:hover:after,
      &.active:after{
        opacity: 1;
      }
    }
  }
</style>
