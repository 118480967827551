<template>
  <div class="fragment">

    <NovaPoshtaCreationPage
            :NP="NP"
            @create="save"
            @getDeliveryPrice="getDeliveryPrice"
    />

    <PayOrderPopup
        v-if="payOrderPopup"
        :orderPrice="orderPrice"
        :disableBtn="$store.getters.getNovaPoshtaBtn || $store.getters.getOrdersInternalBtn"
        @close="changePayOrderPopup(false)"
        @createLabel="save"
    />
  </div>
</template>

<script>
  import NovaPoshtaCreationPage from "./NovaPoshtaCreationPage/NovaPoshtaCreationPage";
  import {NovaPoshta} from "../../models/NovaPoshta";
  import {ORDER_TYPE_EXPRESS, ORDER_TYPE_CONSOLIDATION} from "../../../../../staticData/staticVariables";
  import PayOrderPopup from "../../../../coreComponents/Popups/PayOrderPopup/PayOrderPopup";
  import {novaPoshtaOrdersMixins} from "../../../../../mixins/internalOrdersMixins/novaPoshtaOrdersMixin";

  export default {
    name: "NovaPoshtaCreation",

    components: {
      PayOrderPopup,
      NovaPoshtaCreationPage,
    },

    mixins: [novaPoshtaOrdersMixins],

    computed: {
      user: function() {
        return this.$store.getters.getUserProfile
      }
    },

    watch: {
      user: function(newCount) {
        if(this._.has(newCount, 'id')) {
          this.setUserPhone()
        }
      }
    },

    data() {
      return {
        NP: new NovaPoshta(),

        payOrderPopup: false,

        orderPrice: -1,
      }
    },

    mounted() {

      if(this.$store.getters.getIsAdminRights === 'user'){
        if(Object.keys(this.user).length > 0){
          this.setUserPhone()
        }
      }

      if(this.$route.params.type && this.$route.params.id){
        let id = this.$route.params.id
        let type = this.$route.params.type
        switch (type){
          case ORDER_TYPE_EXPRESS:
            this.NP.setOrderType(id, type)
            this.getDataExpress(id)
            break
          case ORDER_TYPE_CONSOLIDATION:
            this.NP.setOrderType(id, type)
            this.getDataConsolidation(id)
            break
        }
        return
      }

      this.getDefaultWarehouse()

    },

    methods: {

      setUserPhone(){
        let phone = this.$store.getters.GET_COMMON_AUTHORIZED.user.user_personal_contact.phone
        this.NP.setPhone(phone)
      }

    },

  }
</script>

<style scoped>

</style>
