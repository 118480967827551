<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_Shipment', 'poshta_TrackNumber'])"></div>
      <CardLeftBlock
              :name="$t('poshta_Shipment.localization_value.value')"
              :value="'#' + NP.data.id"
              :backgroundImage="'orders'"
      >
        <template slot="body">
          <div class="d-flex mt-3"
               v-if="$store.getters.getOrdersInternalItem.tracking_number"
          >
            <div class="card-detail-left__name">
              {{$t('poshta_TrackNumber.localization_value.value')}}
            </div>
            <div class="card-detail-left__date ml-2">
              #{{$store.getters.getOrdersInternalItem.tracking_number}}
            </div>
          </div>

        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ex_backTo'])"></div>
                <router-link :to="$store.getters.GET_PATHS.novaPoshta" class="order-create__head-back">
                  <LinkBack
                          :value="$t('ex_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta_EditNovaPoshta'])"></div>
              {{$t('poshta_EditNovaPoshta.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta_PleaseInformation'])"></div>
              {{$t('poshta_PleaseInformation.localization_value.value')}}
            </div>

          </div>
        </template>

        <template slot="body">

          <div class="order-create__section">
            <div class="custom-row" v-if="$store.getters.getIsAdminRights === 'admin'">
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                        :serverError="false"
                        :validationUser="NP.validation.user"
                        :validationTxtUser="NP.validationTxt.user"
                        :validationTranslateUser="NP.validationTranslate.user"
                        :userSelected="NP.data.user"
                        @changeUser="changeUser"
                        :typeSelect="'users'"
                        :userEmail="true"
                />
              </div>
            </div>
          </div>

          <StepOne
                  :NP="NP"
          />
        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_SaveInDrafts', 'common_drafts', 'common_savingInDrafts', 'poshta_BuyLabel'])"></div>
              <MainButton
                      class="order-create__footer-btn-i secondary"
                      :value="$t('common_SaveInDrafts.localization_value.value')"
                      :tooltip="true"
                      @click.native="$emit('edit')"
              >
                <template slot="tooltip">
                  <p>
                    <b>{{$t('common_drafts.localization_value.value')}}</b>
                  </p>
                  <p>
                    {{$t('common_savingInDrafts.localization_value.value')}}
                  </p>
                </template>
              </MainButton>

              <MainButton
                      class="order-create__footer-btn-i wfc"
                      :value="$t('poshta_BuyLabel.localization_value.value')"
                      @click.native="$emit('getDeliveryPrice')"
                      v-bind:class="{'disabled-btn' : $store.getters.getNovaPoshtaBtn}"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>


  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import StepOne from "../../components/StepOne/StepOne";
  import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
  import {novaPoshtaOrdersMixins} from "../../../../../../mixins/internalOrdersMixins/novaPoshtaOrdersMixin";
  // import PayOrderPopup from "../../../../OrdersFBMModule/popups/PayOrderPopup/PayOrderPopup";

  export default {
    name: "NovaPoshtaEditingPage",

    components: {
      UserSelect,
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
      StepOne,
      // PayOrderPopup,
    },

    mixins: [novaPoshtaOrdersMixins],

    props: {
      NP: Object,
    },

    data() {
      return {

      }
    },

    methods: {
    }
  }
</script>

<style lang="scss">

  .product-progress-steps {
    max-width: 625px;
  }

</style>
