<template>
  <div class="fragment">


    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator">

      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton
            :count="countFilterParams"
            :showFilter="showFilter"
            @toggleFilter="$emit('toggleFilter')"
        />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">
<!--        <DefaultCheckbox-->
<!--            :label="$t('common_selectAll.localization_value.value')"-->
<!--        />-->
      </div>
      <div class="btn-right-block">
        <router-link :to="$store.getters.GET_PATHS.novaPoshtaCreation">
          <MainButton class="btn-fit-content ml-3"
                      :value="'Add New'"
                      :ico="'ico'"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>
        </router-link>
      </div>
    </div>



  </div>
</template>



<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";


  export default {
    name: "NovaPoshtaHead",
    components: {
      ToggleFilterButton,
      MainButton,
      PlusIconSVG,
      // DefaultCheckbox,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },

    data(){
      return{

      }
    },

    methods: {

    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";

  .light-bg-block{
    display: flex;
    flex-wrap: wrap;
  }

  @include for-992{
    .btn-left-block {
      margin-bottom: 15px;
    }

    .btn-right-block{
      flex-wrap: wrap;
    }

    .main-button{
      margin-bottom: 10px;
    }

  }

  @include for-420{
    .light-bg-block{
      flex-direction: column-reverse;
      align-items: flex-start;
    }

  }

</style>

