<template>
  <div>

    <div class="order-credate__section-sub">
      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col custom-col--16  custom-col--md-25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['packaging_Weight'])"></div>
          <DefaultInput
              :label="$t('packaging_Weight.localization_value.value')"
              :placeholder="'e.g. 10'"
              :type="'text'"
              v-bind:class="{'ui-no-valid': packageNP.validation.weight}"
              :errorTxt="serverError ? packageNP.validationTxt.weight : $t(`${packageNP.validationTranslate.weight}.localization_value.value`)"
              :error="packageNP.validation.weight"
              v-model="packageNP.packaging.weight"
              @input="changePackagingValue"
          />
        </div>
        <div class="order-create__col custom-col custom-col--16  custom-col--md-25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['packaging_Height'])"></div>
          <DefaultInput
              :label="$t('packaging_Height.localization_value.value')"
              :placeholder="'e.g. 10'"
              :type="'text'"
              v-bind:class="{'ui-no-valid': packageNP.validation.height}"
              :errorTxt="serverError ? packageNP.validationTxt.height : $t(`${packageNP.validationTranslate.height}.localization_value.value`)"
              :error="packageNP.validation.height"
              v-model="packageNP.packaging.height"
              @input="changePackagingValue"
          />
        </div>
        <div class="order-create__col custom-col custom-col--16  custom-col--md-25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['packaging_Width'])"></div>
          <DefaultInput
              :label="$t('packaging_Width.localization_value.value')"
              :placeholder="'e.g. 10'"
              :type="'text'"
              v-bind:class="{'ui-no-valid': packageNP.validation.width}"
              :errorTxt="serverError ? packageNP.validationTxt.width : $t(`${packageNP.validationTranslate.width}.localization_value.value`)"
              :error="packageNP.validation.width"
              v-model="packageNP.packaging.width"
              @input="changePackagingValue"
          />
        </div>
        <div class="order-create__col custom-col custom-col--16  custom-col--md-25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['packaging_Length'])"></div>
          <DefaultInput
              :label="$t('packaging_Length.localization_value.value')"
              :placeholder="'e.g. 10'"
              :type="'text'"
              v-bind:class="{'ui-no-valid': packageNP.validation.packageLength}"
              :errorTxt="serverError ? packageNP.validationTxt.packageLength : $t(`${packageNP.validationTranslate.packageLength}.localization_value.value`)"
              :error="packageNP.validation.packageLength"
              v-model="packageNP.packaging['length']"
              @input="changePackagingValue"
          />
        </div>
        <div class="order-create__col custom-col custom-col--16  custom-col--md-25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['packaging_VolumeWeight'])"></div>
          <DefaultInput
              :label="$t('packaging_VolumeWeight.localization_value.value')"
              :placeholder="'e.g. 10'"
              :type="'text'"
              v-model="packageNP.packaging.volumeWeight4000"
              :disabled="true"
          />
        </div>

        <div class="order-create__col custom-col custom-col--16  custom-col--md-0 mb-0">
        </div>

        <div class="order-create__col custom-col custom-col--33  custom-col--md-75 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['poshta_ShippingDate'])"></div>
          <DatePickerDefault
              :label="$t('poshta_ShippingDate.localization_value.value')"
              v-bind:class="{'ui-no-valid': NP.validation.shippingDate}"
              :errorTxt="serverError ? NP.validationTxt.shippingDate : $t(`${NP.validationTranslate.shippingDate}.localization_value.value`)"
              :error="NP.validation.shippingDate"
              v-model="NP.data.shippingDate"
          >
            <template slot="body">
              <date-picker
                  v-model="NP.data.shippingDate"
                  ref="datePicker"
                  valueType="format"
                  :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                  :format="'YYYY-MM-DD'"
                  :placeholder="'YYYY-MM-DD'"
              ></date-picker>
            </template>
          </DatePickerDefault>
        </div>
        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['poshta_ShipToWarehouse'])"></div>
          <DefaultInput
              v-if="_.has(NP.data.shippingWarehouse, 'currentTranslate')"
              :label="$t('poshta_ShipToWarehouse.localization_value.value')"
              :type="'text'"
              v-model="NP.data.shippingWarehouse.currentTranslate.name"
              :disabled="true"
          />
        </div>

        <div class="order-create__col custom-col custom-col--16  custom-col--md-25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['poshta_QtyPackages'])"></div>
          <DefaultInput
              :label="$t('poshta_QtyPackages.localization_value.value')"
              :placeholder="'e.g. 10'"
              :type="'text'"
              v-bind:class="{'ui-no-valid': NP.validation.placesCount}"
              :errorTxt="serverError ? NP.validationTxt.placesCount : $t(`${NP.validationTranslate.placesCount}.localization_value.value`)"
              :error="NP.validation.placesCount"
              v-model="NP.data.placesCount"
          />
        </div>
        <div class="order-create__col custom-col custom-col--16  custom-col--md-25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['poshta_DeclaredValue'])"></div>
          <DefaultInput
              :label="$t('poshta_DeclaredValue.localization_value.value')"
              :placeholder="'e.g. 10'"
              :type="'text'"
              v-bind:class="{'ui-no-valid': NP.validation.declaredValue}"
              :errorTxt="serverError ? NP.validationTxt.declaredValue : $t(`${NP.validationTranslate.declaredValue}.localization_value.value`)"
              :error="NP.validation.declaredValue"
              v-model="NP.data.declaredValue"
          />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// import TitleHorizontal from "../../../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
import DatePicker from 'vue2-datepicker'

export default {
  name: "NovaPoshtaParams",

  components: {
    DatePickerDefault,
    DefaultInput,
    DatePicker,
    // TitleHorizontal
  },

  props: {
    NP: Object,
    packageNP: Object,
  },

  data() {
    return {
      serverError: false,


      address: 'Bryklin str, 9 ofice 293-A',
      eg: '',
      egVolume: '2500',
      ship: 'Kyiv',
    }
  },

  methods: {
    changePackagingValue() {
      this.packageNP.calcVolumeWeight()
      this.packageNP.calcVolumeGeneral()
    },
  },

}
</script>

<style scoped>

</style>
