
<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item">
        <DefaultInput
            :label="$t('poshta__ID.localization_value.value')"
            :type="'text'"
            v-model="id"
        />
      </div>
      <div class="table-filter__item">
        <DatePickerDefault
            :label="$t('poshta__Created.localization_value.value')"
            v-model="date"
        >
          <template slot="body">
            <date-picker
                v-model="date"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
      <div class="table-filter__item"
           v-if="$store.getters.getIsAdminRights === 'admin'"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
        <SearchSelect
                :options="options"
                :label="$t('common_user.localization_value.value')"
                :optionsLabel="'email.contacts[0]'"
                :functionSearch="functionSearchUserFilter"
                @change="changeUsersFilter"
                :selected="userName"
                :typeSelect="'users'"
                :userEmail="true"
        />
      </div>
      <div class="table-filter__item">
        <DefaultInput
            :label="$t('poshta__ItemName.localization_value.value')"
            :type="'text'"
            v-model="itemName"
        />
      </div>
      <div class="table-filter__item">
        <DatePickerDefault
                :label="$t('poshta__Shipped.localization_value.value')"
                v-model="shipped"
        >
          <template slot="body">
            <date-picker
                    v-model="shipped"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
      <div class="table-filter__item">
        <DefaultInput
            :label="$t('poshta__DeclaredValue.localization_value.value')"
            :type="'text'"
            v-model="declaredValue"
        />
      </div>
      <div class="table-filter__item">
        <DefaultInput
            :label="$t('poshta__DeliveryPrice.localization_value.value')"
            :type="'text'"
            v-model="deliveryPrice"
        />
      </div>
      <div class="table-filter__item">
        <DefaultInput
            :label="$t('poshta__Qty.localization_value.value')"
            :type="'text'"
            v-model="placesCount"
        />
      </div>
      <!--<div class="table-filter__item">-->
        <!--<DefaultInput-->
            <!--:label=""-->
            <!--:type="'text'"-->
            <!--v-model="shipped"-->
        <!--/>-->
      <!--</div>-->

<!--      <div class="table-filter__item">-->
<!--        <DefaultInput-->
<!--            :label="'InboundID'"-->
<!--            :type="'text'"-->
<!--            v-model="total"-->
<!--        />-->
<!--      </div>-->

      <div class="table-filter__item">
        <DefaultInput
            :label="'Tracking Number'"
            :type="'text'"
            v-model="trackingNumber"
        />
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  // import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "NovaPoshtaFilter",
    components: {
      SearchSelect,
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      DefaultInput,
      // DefaultSelect,
    },

    props: [
      'showFilter',
      'filterGetParams',
    ],

    mixins: [filterFunc, usersFunc],

    data(){
      return {

        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',
        shipped: this.filterGetParams.shipped ? this.generateFilterDate(this.filterGetParams.shipped, 'MM/DD/YY'): '',
        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        payPalTransaction: this.filterGetParams.payPalTransaction ? this.filterGetParams.payPalTransaction : '',
        shipping: this.filterGetParams.shipping ? this.filterGetParams.shipping : '',
        packageType: this.filterGetParams.packageType ? this.filterGetParams.packageType : '',
        trackingNumber: this.filterGetParams.trackingNumber ? this.filterGetParams.trackingNumber : '',
        itemName: this.filterGetParams.itemName ? this.filterGetParams.itemName : '',
        declaredValue: this.filterGetParams.declaredValue ? this.filterGetParams.declaredValue : '',
        deliveryPrice: this.filterGetParams.deliveryPrice ? this.filterGetParams.deliveryPrice : '',
        placesCount: this.filterGetParams.placesCount ? this.filterGetParams.placesCount : '',

        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',


        options: [],
        optionsCountries: [],

        filterCounts: [
          'date',
          'id',
          'payPalTransaction',
          'shipping',
          'packageType',
          'trackingNumber',
          'itemName',
          'shipped',
          'declaredValue',
          'deliveryPrice',
          'placesCount',

          'userName',
          'userId',
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.id = newVal.id ? newVal.id : ''
        this.payPalTransaction = newVal.payPalTransaction ? newVal.payPalTransaction : ''
        this.shipping = newVal.shipping ? newVal.shipping : ''
        this.packageType = newVal.packageType ? newVal.packageType : ''
        this.trackingNumber = newVal.trackingNumber ? newVal.trackingNumber : ''
        this.itemName = newVal.itemName ? newVal.itemName : ''
        this.shipped = newVal.shipped ? newVal.shipped : ''
        this.declaredValue = newVal.declaredValue ? newVal.declaredValue : ''
        this.deliveryPrice = newVal.deliveryPrice ? newVal.deliveryPrice : ''
        this.placesCount = newVal.placesCount ? newVal.placesCount : ''

        this.userName = newVal.userName ? newVal.userName : ''
        this.userId = newVal.userId ? newVal.userId : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')
        data.shipped = this.changeDateParams(this, 'shipped')

        this.$emit('changeFilter', data)
      },
    },
  }
</script>

<style scoped>

</style>
