<template>
  <div class="nova-poshta-btn">
    <div class="nova-poshta-btn__ico"></div>
  </div>
</template>

<script>
  export default {
    name: "NovaPoshtaBtn",

    components: {

    },
  }
</script>

<style lang="scss">
  @import "../../../../scss/colors";
  @import "../../../../scss/mixins/mixins";


  .nova-poshta-btn{
    cursor: pointer;
    transition: .15s;

    &__ico{
      display: block;
      width: 14px;
      height: 13px;
      background: url("../../../../assets/img/common/nova-poshta-icon.png") center/contain no-repeat;
    }
  }
</style>
