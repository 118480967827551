<template>
  <div class="fragment">
    <NovaPoshtaEditingPage
            :NP="NP"
            @edit="save(true, true)"
            @getDeliveryPrice="getDeliveryPrice"
    />

    <PayOrderPopup
            v-if="payOrderPopup"
            :orderPrice="orderPrice"
            :disableBtn="$store.getters.getNovaPoshtaBtn || $store.getters.getOrdersInternalBtn"
            @close="changePayOrderPopup(false)"
            @createLabel="save(false, true)"
    />
  </div>
</template>

<script>
  import NovaPoshtaEditingPage from "./NovaPoshtaEditingPage/NovaPoshtaEditingPage";
  import {NovaPoshta} from "../../models/NovaPoshta";
  // import {ORDER_TYPE_EXPRESS} from "../../../../../staticVariables";
  import PayOrderPopup from "../../../../coreComponents/Popups/PayOrderPopup/PayOrderPopup";
  import {novaPoshtaOrdersMixins} from "../../../../../mixins/internalOrdersMixins/novaPoshtaOrdersMixin";

  export default {
    name: "NovaPoshtaEditing",

    components: {
      PayOrderPopup,
      NovaPoshtaEditingPage,
    },

    mixins: [novaPoshtaOrdersMixins],

    data() {
      return {
        NP: new NovaPoshta(),

        payOrderPopup: false,

        orderPrice: -1,
      }
    },

    mounted() {

      this.NP.setId(this.$route.params.id)

      this.$store.dispatch('getOrdersInternalEdit', this.NP.getId()).then(() => {
        this.NP.setNovaPoshtaItem(this.$store.getters.getOrdersInternalItem.orderInternal)
      }).catch(error => this.createErrorLog(error))

      // if(this.$route.params.type && this.$route.params.id){
      //   let id = this.$route.params.id
      //   let type = this.$route.params.type
      //   switch (type){
      //     case ORDER_TYPE_EXPRESS:
      //       this.NP.setOrderType(id, type)
      //       this.getDataExpress(id)
      //       break
      //   }
      //
      //   return
      // }
      //
      // this.getDefaultWarehouse()

    },

    methods: {

    },

  }
</script>

<style scoped>

</style>
