
<template>
  <div class="table-filter-wrap">

    <template v-if="$store.getters.getOrdersInternal.length > 0 && $store.getters.getOrdersInternalLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'poshta__ID',
          'poshta__Created',
          'poshta__Customer',
          'poshta__PayPalTransaction',
          'poshta__Shiping',
          'poshta__Type',
          'poshta__ShippingID',
          'poshta__ItemName',
          'poshta__DeclaredValue',
          'poshta__DeliveryPrice',
          'poshta__Shipped',
          'poshta__Qty',
          'common_manage',
        ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
<!--            <th></th>-->
            <th>{{$t('poshta__ID.localization_value.value')}}</th>
            <th>{{$t('poshta__Created.localization_value.value')}}</th>
            <th v-if="isAdmin"> {{$t('common_user.localization_value.value')}}</th>
<!--            <th>{{$t('poshta__Customer.localization_value.value')}}</th>-->
<!--            <th>{{$t('poshta__PayPalTransaction.localization_value.value')}}</th>-->
            <th>{{$t('poshta__Shiping.localization_value.value')}}</th>
<!--            <th>{{$t('poshta__Type.localization_value.value')}}</th>-->
            <th>{{$t('poshta__ShippingID.localization_value.value')}}</th>
            <th>{{$t('poshta__ItemName.localization_value.value')}}</th>
            <th>{{$t('poshta__Shipped.localization_value.value')}}</th>
            <th>{{$t('poshta__DeclaredValue.localization_value.value')}}</th>
            <th>{{$t('poshta__DeliveryPrice.localization_value.value')}}</th>
            <th>{{$t('poshta__Qty.localization_value.value')}}</th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getOrdersInternal" :key="index">
<!--            <td>-->
<!--              <DefaultCheckbox-->
<!--                  class="empty-label"-->
<!--                  :selectedNow="selectedNow"-->
<!--                  :dataValue="item.id"-->
<!--              />-->
<!--            </td>-->
            <td>
              <div class="table-row">
                <router-link
                        class="table-link btn-style"
                        :to="$store.getters.GET_PATHS.novaPoshta + '/show/' + item.id"
                >
                  #{{item.id}}
                </router-link>
              </div>
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>

            <td v-if="isAdmin">
              <TableUserColumn
                      :item="item"
              />
            </td>

            <td align="right">
              <ValueHelper
                  :value="item.shipping_cost"
              />
            </td>

            <td>
              <div class="d-flex align-items-center">
                <NovaPoshtaBtn v-if="item.tracking_number" :type="'blue'" class="mr-1"/>

                <InternalTrackingNumber
                        :item="item"
                        @reload="$emit('reload')"
                />
              </div>
            </td>
            <td>
              <div class="ukr-poshta-item-name">
                <ValueHelper
                    :value="item.description"
                />
              </div>
            </td>
            <td>
              {{ item.shipped_at | moment("DD MMM, YYYY") }}
            </td>
            <td>
              <ValueHelper
                  :value="item.declared_value"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item.delivery_price"
              />
            </td>
            <td align="right">
              <ValueHelper
                  :value="item.places_count"
              />

            </td>
            <td>
              <div class="table-right table-manage-list table-manage-list--small">
                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton"
                  class="w215"
                >
                  <template slot="item"
                            v-if="(INTERNAL_STATUSES.IN_PROGRESS.id === item.order_internal_status_id) ||
                              $store.getters.getIsAdminRights === 'admin'"
                  >
                    <LinkButton
                            :value="$t('common_edit.localization_value.value')"
                        :type="'edit'"
                        :link="$store.getters.GET_PATHS.novaPoshta + '/' + item.id"
                    />
                  </template>

                  <template slot="item"
                            v-if="item.tracking_number"
                  >
                    <LinkButton
                            :link="$store.getters.GET_PATHS.getPdfFile + '/' + getNovaPoshtaLabelUUID(item.file_entries)"
                            :value="$t('poshta_Label.localization_value.value')"
                            :type="'mark'"
                            :target="'_blank'"
                    />
                  </template>
                  <template slot="item"
                            v-if="item.ref_number"
                  >
                    <LinkButton
                            :link="$store.getters.GET_PATHS.getPdfFile + '/' + getNovaPoshtaMarkingUUID(item.file_entries)"
                            :value="$t('poshta_Marking.localization_value.value')"
                            :type="'mark'"
                            :target="'_blank'"
                    />
                  </template>
                  <template slot="item"
                            v-if="(INTERNAL_STATUSES.IN_PROGRESS.id === item.order_internal_status_id && !item.tracking_number)"
                  >
                    <LinkButton
                            :value="$t('poshta_CreateLabel.localization_value.value')"
                        :type="'dog'"
                        @click.native="tableCreateLabel(item)"
                    />
                  </template>
                  <template slot="item"
                            v-if="INTERNAL_STATUSES.COMPLETE.id !== item.order_internal_status_id"
                  >
                    <LinkButton
                            :value="$t('common_delete.localization_value.value')"
                        :type="'delete'"
                        @click.native="removeOrder(item.id)"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>


          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getOrdersInternal"
                 :key="index"
            >
              <NovaPoshtaTableMobile
                      :item="item"
                      @openNovaPoshtaLink="openNovaPoshtaLink"
                      @tableCreateLabel="tableCreateLabel(item)"
                      @removeOrder="removeOrder(item.id)"
                      @reload="$emit('reload')"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getOrdersInternalCommonList.next_page_url !== null && $store.getters.getOrdersInternal.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextOrdersInternalPage}"
                :count="$store.getters.getOrdersInternalCommonList.total - $store.getters.getOrdersInternalForPage * $store.getters.getOrdersInternalCommonList.current_page < $store.getters.getOrdersInternalForPage ?
                  $store.getters.getOrdersInternalCommonList.total - $store.getters.getOrdersInternalForPage * $store.getters.getOrdersInternalCommonList.current_page:
                  $store.getters.getOrdersInternalForPage"
            />
            <!--<ExportBtn-->
                <!--@click.native="$emit('downloadFiles')"-->
                <!--class="table-bottom-btn__right"-->
            <!--/>-->
          </div>
        </div>

      </div>
    </template>

    <template v-if="$store.getters.getOrdersInternalLoading === false && $store.getters.getOrdersInternal.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

    <!--<ProductsToWarehouse @close="closePopup" v-if="isModalProductsToWarehouse"/>-->


    <PayOrderPopup
            v-if="payOrderPopup"
            :orderPrice="orderPrice"
            :disableBtn="$store.getters.getNovaPoshtaBtn || $store.getters.getOrdersInternalBtn"
            @close="changePayOrderPopup(false)"
            @createLabel="createLabel(orderId, true)"
    />

  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import NovaPoshtaBtn from "../../../../../../coreComponents/Buttons/NovaPoshtaBtn/NovaPoshtaBtn";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {INTERNAL_STATUSES} from "../../../../../../../staticData/staticVariables";
  import PayOrderPopup from "../../../../../../coreComponents/Popups/PayOrderPopup/PayOrderPopup";
  import {novaPoshtaOrdersTableMixins} from "../../../../../../../mixins/internalOrdersMixins/novaPoshtaOrdersMixin";
  import NovaPoshtaTableMobile from "./NovaPoshtaTableMobile/NovaPoshtaTableMobile";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import InternalTrackingNumber
    from "../../../../../../coreComponents/TableComponents/InternalTrackingNumber/InternalTrackingNumber";


  export default {
    name: "NovaPoshtaTable",

    components: {
      InternalTrackingNumber,
      TableUserColumn,
      NovaPoshtaTableMobile,
      PayOrderPopup,
      ValueHelper,
      NoResult,
      ShowMore,
      LinkButton,
      ManagerButton,
      NovaPoshtaBtn,
    },

    mixins: [mixinDetictingMobile, novaPoshtaOrdersTableMixins],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },

    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        INTERNAL_STATUSES: INTERNAL_STATUSES,

        payOrderPopup: false,

        // isModalProductsToWarehouse: false,

        show1: false,
        show2: false,
        show3: false,
      }
    },

    methods: {

      tableCreateLabel(item) {
        this.orderId = item.id
        this.getDeliveryPriceFromTable(item.id)
      },

      closePopup(){
        // this.isModalProductsToWarehouse = false
      },

      removeOrder(id){
        let text = {
          title: 'poshta_DeleteNovaPoshtaOrder',
          txt: '',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteOrdersInternal', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let orders = this.$store.getters.getOrdersInternal
              orders.map((item, index) => {
                if(item.id === id) {
                  orders.splice(index, 1)
                }
              })

              this.openNotify('success', 'common_notificationRecordDeleted')
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)


      }
    }

  }

</script>

<style scoped lang="scss">

  .site-table thead th:first-child,
  .site-table tbody td:first-child{
    padding-right: 0;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child{
    padding-right: 0;
  }

  .small-table th,
  .small-table td{
    white-space: nowrap;
  }

  .w215{
    width: 215px;

    @media (max-width: 1740px) {
      width: 100px;
    }
  }

  .ukr-poshta-item-name{
    @media (max-width: 1740px) {
      max-width: 100px;
      overflow: hidden;
      position: relative;
      text-overflow: ellipsis;
    }
  }
</style>
