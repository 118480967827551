<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <div class="table-card__item-number">
          #{{item.id}}
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('poshta__Created.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>

          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('poshta__Shiping.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.shipping_cost"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                      :item="item"
                      :mobile="true"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('poshta__ShippingID.localization_value.value')}}
            </div>
            <div class="table-card__item-info d-flex align-items-center">
              <NovaPoshtaBtn v-if="item.tracking_number" :type="'blue'" class="mr-1"/>

              <InternalTrackingNumber
                      :item="item"
                      @reload="$emit('reload')"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('poshta__ItemName.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.description"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('poshta__Shipped.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.shipped_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('poshta__DeclaredValue.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.declared_value"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('poshta__DeliveryPrice.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.delivery_price"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('poshta__Qty.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.places_count"
              />
            </div>
          </div>
        </div>
        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i"
               v-if="INTERNAL_STATUSES.COMPLETE !== item.order_internal_status_id"
          >
            <LinkButton
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    :link="$store.getters.GET_PATHS.novaPoshta + '/' + item.id"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                    :link="$store.getters.GET_PATHS.getPdfFile + '/' + getNovaPoshtaLabelUUID(item.file_entries)"
                    :value="$t('poshta_Label.localization_value.value')"
                    :type="'mark'"
                    :target="'_blank'"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="item.ref_number"
          >
            <LinkButton
                    :link="$store.getters.GET_PATHS.getPdfFile + '/' + getNovaPoshtaMarkingUUID(item.file_entries)"
                    :value="$t('poshta_Marking.localization_value.value')"
                    :type="'mark'"
                    :target="'_blank'"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="INTERNAL_STATUSES.COMPLETE !== item.order_internal_status_id"
          >
            <LinkButton
                    :value="$t('poshta_CreateLabel.localization_value.value')"
                    :type="'dog'"
                    @click.native="$emit('tableCreateLabel')"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="INTERNAL_STATUSES.COMPLETE !== item.order_internal_status_id"
          >
            <LinkButton
                    :value="$t('common_delete.localization_value.value')"
                    :type="'delete'"
                    @click.native="$emit('removeOrder')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import NovaPoshtaBtn from "../../../../../../../coreComponents/Buttons/NovaPoshtaBtn/NovaPoshtaBtn";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {INTERNAL_STATUSES} from "../../../../../../../../staticData/staticVariables";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import InternalTrackingNumber
    from "../../../../../../../coreComponents/TableComponents/InternalTrackingNumber/InternalTrackingNumber";
  import {novaPoshtaOrdersTableMixins} from "../../../../../../../../mixins/internalOrdersMixins/novaPoshtaOrdersMixin";

  export default {
    name: "NovaPoshtaTableMobile",

    components: {
      InternalTrackingNumber,
      TableUserColumn,
      ValueHelper,
      LinkButton,
      NovaPoshtaBtn,
    },

    mixins: [novaPoshtaOrdersTableMixins],

    props: {
      item: Object,
    },

    data() {
      return {
        INTERNAL_STATUSES: INTERNAL_STATUSES,
        show: false,
      }
    }

  }
</script>

<style scoped>

</style>
