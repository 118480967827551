<template>
  <div>
    <template v-if="isCurrentUserRoleUser">
      <ValueHelper
              :value="item.tracking_number"
      />
    </template>
    <div v-else
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <span class="admin-edit" @click="editTranslate(['poshta_trackingNumber', 'poshta_addNumber'])"></span>
      <template v-if="item.tracking_number !== null && item.tracking_number !== ''">
                  <span class="table-link btn-style">
                    {{item.tracking_number}}
                  </span>
        <div class="table-ico">
          <v-popover
                  class="site-tooltip"
                  offset="5"
                  placement="top-left"
                  @show="hideEditField = true"
                  @hide="hideEditField = false"
          >
                    <span class="site-link">
                        <LinkButton
                                :type="'edit'"
                        />
                    </span>
            <template slot="popover">
              <div class="section-label mb-2">
                {{ $t('poshta_trackingNumber.localization_value.value') }}
              </div>
              <InputEditField
                      :value="item.tracking_number"
                      :propId="item.id"
                      :hideEditField="hideEditField"
                      @onChange="changeTrackingNumber"
              />
              <a v-close-popover ref="closePopover" class="tooltip__close"></a>
            </template>
          </v-popover>
        </div>
      </template>
      <template v-else>
        <div class="">
          <v-popover
                  class="site-tooltip"
                  offset="5"
                  placement="top-left"
                  @show="hideEditField = true"
                  @hide="hideEditField = false"
          >
                      <span class="table-link btn-style">
                        {{ $t('poshta_addNumber.localization_value.value') }}
                      </span>

            <template slot="popover">
              <div class="section-label mb-2">
                {{ $t('poshta_trackingNumber.localization_value.value') }}
              </div>
              <InputEditField
                      :value="''"
                      :propId="item.id"
                      :hideEditField="hideEditField"
                      @onChange="changeTrackingNumber"
              />

              <a v-close-popover ref="closePopover" class="tooltip__close"></a>
            </template>
          </v-popover>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import InputEditField from "../../../UI/inputs/InputEditField/InputEditField";
  import LinkButton from "../../../UI/buttons/LinkButton/LinkButton";
  import ValueHelper from "../../ValueHelper/ValueHelper";
  import {mixinDetictingMobile} from "../../../../mixins/mobileFunctions";
  export default {
    name: "InternalTrackingNumber",
    components: {ValueHelper, LinkButton, InputEditField},

    mixins: [mixinDetictingMobile],

    props: {
      item: Object,
    },


    data(){
      return{
        hideEditField: false,
      }
    },

    methods: {
      changeTrackingNumber({value, id, ref}) {
        let data = {
          tracking_number: value
        }
        this.$store.dispatch('updateOrdersInternalTrackingNumber', {id: id, data: data}).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

            this.$emit('reload')

            if (!this.isMobileFunc()) {
              this.$refs.closePopover.click()
            } else {
              ref.click()
            }

          setTimeout(() => {
            this.openNotify('success', 'common_notificationRecordChanged')
          }, 500)

          } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;

            setTimeout(() => {
              this.openNotify('error', {txtServer: errors['tracking_number'][0]})
            }, 200)

          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationError')
            }, 200)
          }

        })
      },
    }
  }
</script>

<style scoped>

</style>
