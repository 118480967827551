<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_Shipment', 'poshta_TrackNumber'])"></div>
      <CardLeftBlock
              :name="$t('poshta_Shipment.localization_value.value')"
              :value="'#' + NP.data.id"
              :backgroundImage="'orders'"
      >
        <template slot="body">
          <div class="d-flex mt-3"
               v-if="NP.data.trackingNumber"
          >
            <div class="card-detail-left__name">
              {{$t('poshta_TrackNumber.localization_value.value')}}
            </div>
            <div class="card-detail-left__date ml-2">
              #{{NP.data.trackingNumber}}
            </div>
          </div>

        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ex_backTo'])"></div>
                <router-link :to="$store.getters.GET_PATHS.novaPoshta" class="order-create__head-back">
                  <LinkBack
                          :value="$t('ex_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>

            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta_OrderNovaPoshta'])"></div>
              {{$t('poshta_OrderNovaPoshta.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <div class="order-create__section-label section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta__PersonalInformation'])"></div>
              {{$t('poshta__PersonalInformation.localization_value.value')}}
            </div>

            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col">
                <div class="order-create__table block-table">
                  <div class="block-table__row">
                    <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['poshta__Phone'])"></div>
                      <div class="block-table__label">
                        {{$t('poshta__Phone.localization_value.value')}}
                      </div>
                      <div class="block-table__content">
                        {{NP.data.phone}}
                      </div>
                    </div>
                  </div>

                  <div class="order-create__section-label section-label mt-2"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['poshta__ServiceType'])"></div>
                    {{$t('poshta__ServiceType.localization_value.value')}}
                  </div>

                  <div class="block-table__row">
                    <div class="block-table__col"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['poshta__PersonalAddress', 'poshta__SenderAddress'])"></div>
                      <RadioDefault
                              v-if="NP.data.serviceType.value === NP.data.serviceTypes.personal.value"
                              class="white-space-line"
                              :label="$t('poshta__PersonalAddress.localization_value.value')"
                              :name="'insuranceRadio'"
                              @change.native="changeRadio(0)"
                              :value="true"
                              :disabled="true"
                      />
                      <RadioDefault
                              v-if="NP.data.serviceType.value === NP.data.serviceTypes.novaPoshta.value"
                              class="white-space-line"
                              :label="$t('poshta__SenderAddress.localization_value.value')"
                              :name="'insuranceRadio'"
                              @change.native="changeRadio(0)"
                              :value="true"
                              :disabled="true"
                      />
                      <div class="block-table__content pl-4 pt-2"
                           v-if="NP.data.serviceType.value === NP.data.serviceTypes.personal.value"
                      >
                       {{NP.data.senderAddress.sender_full_address}}
                      </div>
                      <div class="block-table__content pl-4 pt-2"
                           v-if="NP.data.serviceType.value === NP.data.serviceTypes.novaPoshta.value"
                      >
                        {{NP.data.warehouseAddress.sender_full_address}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="order-create__section-label section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta__ProductInformation', 'poshta__SenderAddress'])"></div>
              {{$t('poshta__ProductInformation.localization_value.value')}}
            </div>

            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col">
                <div class="order-create__table block-table">

                  <div class="block-table__row">
                    <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['packaging_Weight'])"></div>
                      <div class="block-table__label">
                        {{$t('packaging_Weight.localization_value.value')}}
                      </div>
                      <div class="block-table__content">
                        {{NP.data.package.packaging.weight}}
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['packaging_Height'])"></div>
                      <div class="block-table__label">
                        {{$t('packaging_Height.localization_value.value')}}
                      </div>
                      <div class="block-table__content">
                        {{NP.data.package.packaging.height}}
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['packaging_Width'])"></div>
                      <div class="block-table__label">
                        {{$t('packaging_Width.localization_value.value')}}
                      </div>
                      <div class="block-table__content">
                        {{NP.data.package.packaging.width}}
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['packaging_Length'])"></div>
                      <div class="block-table__label">
                        {{$t('packaging_Length.localization_value.value')}}
                      </div>
                      <div class="block-table__content">
                        {{NP.data.package.packaging['length']}}
                      </div>
                    </div>
                  </div>

                  <div class="block-table__row">
                    <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['poshta_ShippingDate'])"></div>
                      <div class="block-table__label">
                        {{$t('poshta_ShippingDate.localization_value.value')}}
                      </div>
                      <div class="block-table__content">
                        {{ NP.data.shippingDate | moment("DD MMM, YYYY") }}
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['poshta__ShippingID'])"></div>
                      <div class="block-table__label">
                        {{$t('poshta__ShippingID.localization_value.value')}}
                      </div>
                      <div class="block-table__content">
                        {{ NP.data.trackingNumber }}
                      </div>
                    </div>
                    <!--<div class="block-table__col block-table__col&#45;&#45;20 block-table__col&#45;&#45;sm-50"-->
                         <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
                      <!--<div class="admin-edit" @click="editTranslate(['poshta_ShipToWarehouse'])"></div>-->
                      <!--<div class="block-table__label">-->
                        <!--{{$t('poshta_ShipToWarehouse.localization_value.value')}}-->
                      <!--</div>-->
                      <!--<div class="block-table__content">-->
                        <!--&lt;!&ndash;{{NP.data.shippingWarehouse.currentTranslate.name}}&ndash;&gt;-->
                      <!--</div>-->
                    <!--</div>-->
                    <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['poshta_QtyPackages'])"></div>
                      <div class="block-table__label">
                        {{$t('poshta_QtyPackages.localization_value.value')}}
                      </div>
                      <div class="block-table__content">
                        {{NP.data.placesCount}}
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['poshta_DeclaredValue'])"></div>
                      <div class="block-table__label">
                        {{$t('poshta_DeclaredValue.localization_value.value')}}
                      </div>
                      <div class="block-table__content">
                        {{NP.data.declaredValue}}
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['poshta_LabelPrice'])"></div>
                      <div class="block-table__label">
                        {{$t('poshta_LabelPrice.localization_value.value')}}
                      </div>
                      <div class="block-table__content">
                        <ValueHelper
                          :value="NP.data.labelPrice"
                          />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="order-create__section-label section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta__CargoDescription'])"></div>
              {{$t('poshta__CargoDescription.localization_value.value')}}
            </div>

            <div class="block-table__row">
              <div class="block-table__col block-table__col--66 block-table__col--sm-100">
                {{NP.data.description}}
              </div>
            </div>

          </div>
        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <!--<div class="order-create__footer-link">-->
              <!--<div class="site-link site-link&#45;&#45;alt mr-0">-->
                <!--Delete Order-->
              <!--</div>-->
            <!--</div>-->


            <!--<div class="order-create__footer-btn ml-auto">-->
              <!--<MainButton-->
                      <!--class="order-create__footer-btn-i wfc"-->
                      <!--:value="'Create a Copy'"-->
              <!--/>-->
            <!--</div>-->

          </div>
        </template>
      </CardRightBlock>

    </div>

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import CardRightBlock from "../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../UI/buttons/LinkBack/LinkBack";
  // import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import {mixinDetictingMobile} from "../../../../../mixins/mobileFunctions";
  import RadioDefault from "../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import {novaPoshtaOrdersMixins} from "../../../../../mixins/internalOrdersMixins/novaPoshtaOrdersMixin";
  import {NovaPoshta} from "../../models/NovaPoshta";
  import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "NovaPoshtaShow",

    mixins: [mixinDetictingMobile, novaPoshtaOrdersMixins],

    components: {
      ValueHelper,
      CardLeftBlock,
      CardRightBlock,
      LinkBack,
      // MainButton,
      RadioDefault,
    },

    data() {
      return {
        NP: new NovaPoshta(),
      }
    },

    mounted() {

      this.NP.setId(this.$route.params.id)

      this.$store.dispatch('getOrdersInternal', this.NP.getId()).then(() => {
        this.NP.setNovaPoshtaItem(this.$store.getters.getOrdersInternalItem.orderInternal)
      }).catch(error => this.createErrorLog(error))

    }

  }
</script>

<style scoped lang="scss">
  @import "../../../../../scss/mixins/mixins";

  @include for-680{
    .order-create__footer-link{
      width: 100%;
    }
  }

</style>
