<template>
  <modal
          @close="$emit('close')"
          class="custom-popup medium-size"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_AddressControl'])"></div>
        {{$t('poshta_AddressControl.localization_value.value')}}
      </div>
    </template>
    <template slot="body">

      <TabsInPopup>
        <template slot="item-tab">
          <div class="tabs-popup__item"
               v-bind:class="{
                 'active' : activeTab === 1,
                 'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()
               }"
               @click="changeTab(1)">
            <div class="admin-edit" @click="editTranslate(['poshta_MyAddresses'])"></div>
            {{$t('poshta_MyAddresses.localization_value.value')}}
          </div>
          <div class="tabs-popup__item"
               v-bind:class="{
                 'active' : activeTab === 2,
                 'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()
               }"
               @click="changeTab(2)"
          >
            <div class="admin-edit" @click="editTranslate(['poshta_NewAddress'])"></div>
            {{$t('poshta_NewAddress.localization_value.value')}}
          </div>
        </template>
      </TabsInPopup>

      <div class="custom-popup__content mb-4 mt-4"
        v-show="activeTab === 1"
      >

        <div class="custom-row">
          <div class="transaction-info__col justify-content-center">
              <div class="transaction-info__txt"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_MyAddresses'])"></div>
                {{$t('poshta_MyAddresses.localization_value.value')}}
              </div>
          </div>
        </div>

        <div class="custom-row">
          <div class="custom-col">
            <div class="nova-poshta-sender-address mt-3">
              <div class="nova-poshta-sender-address__item"
                   v-for="(item, index) in userWarehouses"
                   :key="index"
              >
                {{item.warehouse.sender_full_address}}
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="custom-popup__content"
         v-show="activeTab === 2"
      >

        <div class="section-label mt-4 mb-2"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['poshta_MyAddresses'])"></div>
          {{$t('poshta_SearchCity.localization_value.value')}}
        </div>

        <div class="custom-row">
          <div class="custom-col custom-col--100  custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['poshta_SearchCity'])"></div>
            <SearchSelect
                :options="cities"
                :label="$t('poshta_SearchCity.localization_value.value')"
                :functionSearch="(val, loading) => functionSearchNovaPoshta(val, loading, 'city')"
                :optionsLabel="'Present'"
                @change="selectNovaPoshtaCity"
            />
          </div>
        </div>

        <template>
          <div class="section-label mb-2"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['poshta_SearchStreet'])"></div>
            {{$t('poshta_SearchStreet.localization_value.value')}}
          </div>

          <div class="custom-row">
            <div class=" custom-col custom-col--100 custom-col--sm-50 custom-padding"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta_SearchStreet'])"></div>
              <SearchSelect
                  :options="streets"
                  :label="$t('poshta_SearchStreet.localization_value.value')"
                  :functionSearch="(val, loading) => functionSearchNovaPoshta(val, loading, 'street')"
                  :optionsLabel="'Description'"
                  @change="selectNovaPoshtaStreets"
              />
<!--              <DefaultSelect-->
<!--                  :options="streets"-->
<!--                  :label="'Street'"-->
<!--                  :optionsLabel="'Description'"-->
<!--                  @change="changeStreet"-->
<!--              />-->
            </div>
          </div>
        </template>


        <template>
        <div class="custom-row">
          <div class="custom-col custom-col--33 custom-col--md-25 custom-col--sm-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['poshta_BuildingNumber'])"></div>
            <DefaultInput
                    :label="$t('poshta_BuildingNumber.localization_value.value')"
                    :type="'text'"
                    :placeholder="'e.g. 10'"
                    v-model="buildingNum"
            />
          </div>
          <div class="custom-col custom-col--33 custom-col--md-25 custom-col--sm-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['poshta_Flat'])"></div>
            <DefaultInput
                    :label="$t('poshta_Flat.localization_value.value')"
                    :type="'text'"
                    :placeholder="'e.g. 10'"
                    v-model="flat"
            />
          </div>
          <div class="custom-col custom-col--33 custom-col--md-25 custom-col--sm-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['poshta_Note'])"></div>
            <DefaultInput
                    :label="$t('poshta_Note.localization_value.value')"
                    :type="'text'"
                    :placeholder="'e.g. 10'"
                    v-model="note"
            />
          </div>
        </div>
        </template>
      </div>

    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_save'])"></div>
        <span
                class="site-link site-link--alt buy-label__btn-i mr-3"
                @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
                class="buy-label__btn-i wfc"
                :value="$t('common_save.localization_value.value')"
                @click.native="saveAddressControl"
                v-if="activeTab === 2"
                v-bind:class="{'disabled-btn' : $store.getters.getNovaPoshtaBtn || !checkFillingOfFields}"
        />
      </div>
    </template>
  </modal>

</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import TabsInPopup from "../../../../coreComponents/Popups/TabsInPopup/TabsInPopup";
  // import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import SearchSelect from "../../../../UI/selectiones/SearchSelect/SearchSelect";

  export default {
    name: "AddressControlPopup",
    components: {
      SearchSelect,
      DefaultInput,
      // DefaultSelect,
      TabsInPopup,
      Modal,
      MainButton,
    },

    computed: {
      checkFillingOfFields() {
        if(
          Object.keys(this.selectedCity).length > 0 &&
          Object.keys(this.selectStreet).length > 0 &&
          this.buildingNum.length > 0 &&
          this.flat.length > 0
        ){
          return true
        }
        return false
      }
    },

    data() {
      return {
        options: [],

        activeTab: 2,

        userWarehouses: [],

        cities: [],
        selectedCity: {},

        streets: [],
        selectStreet: {},

        buildingNum: '',
        flat: '',
        note: '',

      }
    },

    created() {
      let data = {}
      if(this.$store.getters.getIsAdminRights === 'admin'){
        if(this.NP.getUserId() === '') return

        data['user_id'] = this.NP.getUserId()
      }

      this.$store.dispatch('getUserSenderAddresses', data).then(response => {

        this.userWarehouses = []
        response.map((item) => {
          this.userWarehouses.push({
            warehouse: item,
            selected: false,
          })
        })
      })
    },

    methods: {
      changeTab(val){
        this.activeTab = val
      },

      functionSearchNovaPoshta(val, loading, type = 'city') {
        loading(true)
        if (this.searchTimeoutTime !== undefined) {
          let dateNow = new Date()
          if (dateNow - this.searchTimeoutTime < 500) {
            clearTimeout(this.searchTimeout)
          }
        }

        this.createSearchTimeOut(val, loading, type)
      },

      createSearchTimeOut(val, loading, type) {
        this.searchTimeoutTime = new Date()
        this.searchTimeout = setTimeout(() => {

          switch (type) {
            case 'city' :
              this.getCities(val, loading)
              break
            case 'street' :
              this.getStreets(val, loading)
              break
          }
        }, 500)
      },

      getCities(val = false, loading){

        let data = {
          search: val ? val : ''
        }

        if(val && val.length < 3) return

        this.$store.dispatch('getNovaPoshtaCities', data).then((response) => {
          this.cities = response[0]['Addresses']
          if(loading)
            loading(false)
        }).catch(error => this.createErrorLog(error))
      },

      getStreets(val = false, loading){

        let data = {
          cityRef: this.selectedCity['DeliveryCity'] ? this.selectedCity['DeliveryCity'] : '',
          search: val ? val : ''
        }
        console.log(data);

        if(val && val.length < 3) return

        this.$store.dispatch('getNovaPoshtaStreets', data).then((response) => {
          this.streets = response
          if(loading)
            loading(false)
        })
      },

      selectNovaPoshtaCity(val) {
        this.selectedCity = val
        console.log(this.selectedCity);
        this.selectStreet = {}

        let data = {
          cityRef: this.selectedCity['DeliveryCity'],
          search: '',
        }

        this.$store.dispatch('getNovaPoshtaStreets', data).then((response) => {
          this.streets = response
        })
      },

      selectNovaPoshtaStreets(val) {
        this.selectStreet = val
      },

      saveAddressControl(){
        let data = {
          'streetRef': this.selectStreet.Ref,
          'buildingNumber': this.buildingNum,
          'flat': this.flat,
          'note': this.note
        }

        this.$store.dispatch('createUserNovaPoshtaAddress', data).then((response) => {
          if(this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE){

            let savedAddress = {
              sender_address_ref: response.data.data[0]['Ref'],
              sender_city_ref: this.selectedCity['DeliveryCity'],
              sender_full_address: this.selectedCity['Present'] + ' ' + response.data.data[0]['Description'],
            }
            this.$emit('savedAddress', savedAddress)

            this.openNotify('success', 'common_notificationRecordCreated')

          } else if(response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;
            console.log(errors);
            // this.serverError = true;

            // errors.password ? ( this.validation.password = true, this.validationTxt.password = errors.password[0] ) : false;
          }
        })
      }
    },
  }

</script>

<style scoped lang="scss">
  @import '../../../../../scss/colors';

  .nova-poshta-sender-address {
    display: flex;
    flex-wrap: wrap;

    &__item {
      padding: 5px 12px;
      background: $borderBrown;
      margin-right: 8px;
      margin-bottom: 8px;
      border-radius: 4px;
      transition: 0.3s;
      color: $white;
      font-size: 14px;
      line-height: 16px;
      cursor: pointer;

      //&:hover, &--active {
      //  background: $brown;
      //}
    }
  }

  .tabs-popup__item .admin-edit{
    top: 0;
  }
</style>
