<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <div v-if="serviceType.value === NOVAPOSHTA_SERVICE_TYPES.warehouseWarehouse.value"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_SenderWarehouse'])"></div>
        {{ $t('poshta_SenderWarehouse.localization_value.value') }}
      </div>

      <div v-if="serviceType.value === NOVAPOSHTA_SERVICE_TYPES.doorsWarehouse.value"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_SenderAddress'])"></div>
        {{ $t('poshta_SenderAddress.localization_value.value') }}
      </div>

    </template>
    <template slot="body">

      <div class="custom-popup__content mb-4">


        <div class="custom-row">
          <div class="transaction-info__col justify-content-center">
            <template
              v-if="this.serviceType.value === NOVAPOSHTA_SERVICE_TYPES.warehouseWarehouse.value"
            >
              <div class="transaction-info__txt"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_MyWarehouses'])"></div>
                {{ $t('poshta_MyWarehouses.localization_value.value') }}
              </div>
            </template>
            <template
              v-if="this.serviceType.value === NOVAPOSHTA_SERVICE_TYPES.doorsWarehouse.value"
            >
              <div class="transaction-info__txt"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_MyAddresses'])"></div>
                {{ $t('poshta_MyAddresses.localization_value.value') }}
              </div>
            </template>
          </div>
          <div class="transaction-info__col align-items-end"
               v-if="serviceType === NOVAPOSHTA_SERVICE_TYPES.doorsWarehouse"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit right" @click="editTranslate(['poshta_AddAddress'])"></div>
            <MainButton
                :value="$t('poshta_AddAddress.localization_value.value')"
                class="buy-label__btn-i wfc"
                @click.native="$emit('openControl')"
            />
          </div>
        </div>

        <div class="custom-row">
          <div class="custom-col">
            <div class="nova-poshta-sender-address mt-3">
              <div class="nova-poshta-sender-address__item"
                   :class="{'nova-poshta-sender-address__item--active' : item.selected}"
                   v-for="(item, index) in userWarehouses"
                   :key="index"
                   @click="chooseSenderWarehouse(index)"
              >
                {{item.warehouse.sender_full_address}}
                <!--                Kyiv, Kyivsky dist.-->
              </div>
            </div>
          </div>
        </div>


        <template
            v-if="serviceType.value === NOVAPOSHTA_SERVICE_TYPES.warehouseWarehouse.value"
        >
          <div class="section-label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['poshta_AnotherWarehouse'])"></div>
            {{ $t('poshta_AnotherWarehouse.localization_value.value') }}
          </div>

          <div class="custom-row">
            <div class="custom-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta_SearchStreet'])"></div>
              <SearchSelect
                  :options="cities"
                  :label="$t('poshta_SearchStreet.localization_value.value')"
                  :functionSearch="functionSearchNovaPoshtaCity"
                  :optionsLabel="'Present'"
                  @change="selectNovaPoshtaCity"
              />
            </div>
          </div>

          <div class="custom-row">
            <div class="custom-col">
              <div class="nova-poshta-sender-address mt-3">
                <div class="nova-poshta-sender-address__item"
                     :class="{'nova-poshta-sender-address__item--active' : item.selected}"
                     v-for="(item, index) in novaPoshtaWarehouses"
                     :key="index"
                     @click="chooseNovaPoshtaWarehouse(index)"
                >
                  {{item.warehouse['Description']}}
                  <!--                Kyiv, Kyivsky dist.-->
                </div>
              </div>
            </div>
          </div>
        </template>

      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'poshta_Apply'])"></div>
        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="$emit('close')"
        >
          {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
            class="buy-label__btn-i wfc"
            :value="$t('poshta_Apply.localization_value.value')"
            @click.native="applyPopup"
            v-bind:class="{'disabled-btn' : $store.getters.getNovaPoshtaBtn || !checkSelectedWarehouse}"
        />
      </div>
    </template>
  </modal>

</template>

<script>
import Modal from '../../../../commonModals/Modal.vue'
import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
import {NOVAPOSHTA_SERVICE_TYPES} from "../../../../../staticData/staticVariables";
import {radioButtonsFunc} from "../../../../../mixins/creationPageMixins/radioButtonsFunc";
// import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
import SearchSelect from "../../../../UI/selectiones/SearchSelect/SearchSelect";

export default {
  name: "AddressPopup",

  components: {
    SearchSelect,
    Modal,
    MainButton,
    // DefaultInput,
  },

  mixins: [radioButtonsFunc],

  props: {
    NP: Object,
    serviceType: Object,
    typesSenderWarehouse: Object,
  },

  computed: {
    checkSelectedWarehouse() {
      let selectedUserWarehouses = this._.find(this.userWarehouses, {selected: true})
      if(selectedUserWarehouses) return true

      let selectedNovaPoshtaWarehouses = this._.find(this.novaPoshtaWarehouses, {selected: true})
      if(selectedNovaPoshtaWarehouses) return true

      return false
    }
  },

  data() {
    return {
      NOVAPOSHTA_SERVICE_TYPES: NOVAPOSHTA_SERVICE_TYPES,

      userWarehouses: [],

      cities: [],
      selectCity: {},
      cityRef: '',

      novaPoshtaWarehouses: [],
    }
  },

  created() {

    let data = {}
    if(this.$store.getters.getIsAdminRights === 'admin'){
      if(this.NP.getUserId() === '') return

      data['user_id'] = this.NP.getUserId()
    }

    if(this.serviceType.value === NOVAPOSHTA_SERVICE_TYPES.warehouseWarehouse.value){
      this.$store.dispatch('getUserSenderWarehouses', data).then(response => {

        this.userWarehouses = []
        response.map((item) => {
          this.userWarehouses.push({
            warehouse: item,
            selected: false,
          })
        })
      })
    }

    if(this.serviceType.value === NOVAPOSHTA_SERVICE_TYPES.doorsWarehouse.value){
      this.$store.dispatch('getUserSenderAddresses', data).then(response => {

        this.userWarehouses = []
        response.map((item) => {
          this.userWarehouses.push({
            warehouse: item,
            selected: false,
          })
        })
      })
    }
  },

  methods: {
    chooseSenderWarehouse(index){
      // skip select user novaPoshta warehouse
      this.skipRadioChecked(this.novaPoshtaWarehouses, 'selected')

      this.skipRadioChecked(this.userWarehouses, 'selected')
      this.userWarehouses[index].selected = !this.userWarehouses[index].selected
    },

    applyPopup() {
      if(this.serviceType.value === NOVAPOSHTA_SERVICE_TYPES.warehouseWarehouse.value){
        let typeWarehouse
        let selected

        let selectedWarehouse = this._.find(this.userWarehouses, {selected: true})
        if(selectedWarehouse) {
          selected = selectedWarehouse.warehouse
          typeWarehouse = this.typesSenderWarehouse.warehouse
        }


        let selectedNovaPoshtaWarehouses = this._.find(this.novaPoshtaWarehouses, {selected: true})
        if(selectedNovaPoshtaWarehouses) {
          selected = {
            city: this.selectCity,
            warehouse: selectedNovaPoshtaWarehouses.warehouse
          }
          typeWarehouse = this.typesSenderWarehouse.warehouseNovaPoshta
        }

        this.$emit('applyWarehouseWarehouseAddress', {
          selected: selected,
          typeSenderWarehouse: typeWarehouse
        })
      }


      if(this.serviceType.value === NOVAPOSHTA_SERVICE_TYPES.doorsWarehouse.value){

        let selectedWarehouse = this._.find(this.userWarehouses, {selected: true})
        this.$emit('applySenderWarehouseAddress', {
          selected: selectedWarehouse.warehouse
        })
      }

    },

    functionSearchNovaPoshtaCity(val, loading) {
      loading(true)

      if (this.proformSearchTimeoutTime !== undefined) {
        let dateNow = new Date()
        if (dateNow - this.proformSearchTimeoutTime < 500) {
          clearTimeout(this.proformSearchTimeout)
        }
      }

      this.createTimeOut(val, loading)
    },

    createTimeOut(val, loading) {
      this.proformSearchTimeoutTime = new Date()
      this.proformSearchTimeout = setTimeout(() => {
        this.getCities(val, loading)
      }, 500)
    },

    getCities(val = false, loading){

      let data = {
        search: val ? val : ''
      }

      if(val && val.length < 3) return

      this.$store.dispatch('getNovaPoshtaCities', data).then((response) => {
        this.cities = response[0]['Addresses']
        if(loading)
          loading(false)
      }).catch(error => this.createErrorLog(error))
    },

    selectNovaPoshtaCity(val) {
      this.selectCity = val
      this.cityRef = val['DeliveryCity']

      this.$store.dispatch('getNovaPoshtaWarehouseFromCity', {cityRef: this.cityRef}).then((response) => {

        this.novaPoshtaWarehouses = []
        response.map(item => {
          this.novaPoshtaWarehouses.push({
            warehouse: item,
            selected: false,
          })
        })

      })
    },

    chooseNovaPoshtaWarehouse(index) {
      // skip select user warehouse
      this.skipRadioChecked(this.userWarehouses, 'selected')

      this.skipRadioChecked(this.novaPoshtaWarehouses, 'selected')
      this.novaPoshtaWarehouses[index].selected = !this.novaPoshtaWarehouses[index].selected
    },

  },

}

</script>

<style scoped lang="scss">
@import '../../../../../scss/colors';



.nova-poshta-sender-address{
  display: flex;
  flex-wrap: wrap;

  &__item{
    padding: 5px 12px;
    background: $borderBrown;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    transition: 0.3s;
    color: $white;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;

    &:hover,&--active{
      background: $brown;
    }
  }
}
</style>
