<template>
  <div class="order-create__section">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta__PersonalInformation'])"></div>
      {{$t('poshta__PersonalInformation.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta__Phone'])"></div>
        <DefaultInput
                :label="$t('poshta__Phone.localization_value.value')"
                :type="'tel'"
                :autocomplete="'tel'"
                v-bind:class="{'ui-no-valid': NP.validation.phone}"
                :errorTxt="serverError ? NP.validationTxt.phone : $t(`${NP.validationTranslate.phone}.localization_value.value`)"
                :error="NP.validation.phone"
                v-model="NP.data.phone"
        />
      </div>
    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta__ServiceType'])"></div>
      {{$t('poshta__ServiceType.localization_value.value')}}
    </div>

    <div class="custom-row">
      <div class="custom-col custom-col--66 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta__PersonalAddress', 'poshta__SenderAddress'])"></div>
        <RadioDefault
                class="white-space-line"
                :label="$t('poshta__PersonalAddress.localization_value.value')"
                :name="'insuranceRadio'"
                :value="NP.data.serviceType.value === NP.data.serviceTypes.personal.value"
                @input="NP.setServiceType(NP.data.serviceTypes.personal)"
        />


        <DefaultInput
                class="ml-4 mt-3"
                v-if="NP.data.serviceType.value === NP.data.serviceTypes.personal.value"
                :label="$t('poshta__SenderAddress.localization_value.value')"
                :type="'text'"
                :value="NP.data.senderAddress.sender_full_address"

                v-bind:class="{'ui-no-valid': NP.validation.senderAddress}"
                :errorTxt="serverError ? NP.validationTxt.senderAddress : $t(`${NP.validationTranslate.senderAddress}.localization_value.value`)"
                :error="NP.validation.senderAddress"

                @click.native="addressPopupChange(true)"
        />
      </div>
      <div class="custom-col custom-col--66 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta__NovaPoshtaAddress', 'poshta__SenderAddress'])"></div>
        <RadioDefault
                class="white-space-line"
                :label="$t('poshta__NovaPoshtaAddress.localization_value.value')"
                :name="'insuranceRadio'"
                :value="NP.data.serviceType.value === NP.data.serviceTypes.novaPoshta.value"
                @input="NP.setServiceType(NP.data.serviceTypes.novaPoshta)"
        />

        <DefaultInput
                :label="$t('poshta__SenderAddress.localization_value.value')"
                :type="'text'"
                class="ml-4 mt-3"
                @click.native="addressPopupChange(true)"
                v-if="NP.data.serviceType.value === NP.data.serviceTypes.novaPoshta.value"
                :value="warehouseWarehouseAddress"

                v-bind:class="{'ui-no-valid': NP.validation.warehouseAddress}"
                :errorTxt="serverError ? NP.validationTxt.warehouseAddress : $t(`${NP.validationTranslate.warehouseAddress}.localization_value.value`)"
                :error="NP.validation.warehouseAddress"

                :readonly="true"
        />
      </div>
    </div>




    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta__ProductInformation'])"></div>
      {{$t('poshta__ProductInformation.localization_value.value')}}
    </div>

    <NovaPoshtaParams
            :NP="NP"
            :packageNP="NP.data.package"
    />

    <!--<div class="order-create__product-btn add-product-btn-wrap">-->
					<!--<span class="order-create__product-btn-i add-product-btn site-link"-->
                <!--@click="NP.addNovaPoshtaProduct()"-->
          <!--&gt;-->
						<!--+ Add Product-->
					<!--</span>-->
    <!--</div>-->

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta__CargoDescription'])"></div>
      {{$t('poshta__CargoDescription.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta__Comment'])"></div>
        <TextareaDefault
                :label="$t('poshta__Comment.localization_value.value')"
                v-bind:class="{'ui-no-valid': NP.validation.description}"
                :errorTxt="serverError ? NP.validationTxt.description : $t(`${NP.validationTranslate.description}.localization_value.value`)"
                :error="NP.validation.description"
                v-model="NP.data.description"
        />
      </div>
    </div>

    <AddressPopup
      v-if="addressPopup"
      :serviceType="NP.data.serviceType"
      :typesSenderWarehouse="NP.data.typesSenderWarehouse"
      :NP="NP"
      @close="addressPopupChange(false)"
      @applyWarehouseWarehouseAddress="applyWarehouseWarehouseAddress"
      @applySenderWarehouseAddress="applySenderWarehouseAddress"
      @openControl="() => {addressControlPopupChange(true), addressPopupChange(false)}"
    />

<!--    <SenderWarehousePopup-->
<!--      v-if="addressPopup"-->
<!--      :serviceType="NP.data.serviceType"-->
<!--      @close="addressPopupChange(false)"-->
<!--      @applyWarehouseAddress="applyWarehouseAddress"-->
<!--      @openControl="() => {addressControlPopupChange(true), addressPopupChange(false)}"-->
<!--    />-->

    <AddressControlPopup
        v-if="addressControlPopup"
        @savedAddress="savedAddressControlPopup"
        @close="addressControlPopupChange(false)"
    />
  </div>
</template>

<script>

  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  import RadioDefault from "../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import AddressPopup from "../../../popups/AddressPopup/AddressPopup";
  import AddressControlPopup from "../../../popups/AddressControlPopup/AddressControlPopup";
  import NovaPoshtaParams from "./NovaPoshtaParams/NovaPoshtaParams";
  // import SenderWarehousePopup from "../../../../../popups/SenderWarehousePopup/SenderWarehousePopup";


  export default {
    name: "StepOne",

    components: {
      NovaPoshtaParams,
      AddressControlPopup,
      AddressPopup,
      TextareaDefault,
      RadioDefault,
      DefaultInput,
      // SenderWarehousePopup,
    },

    mixins: [mixinDetictingMobile],

    props: {
      NP: Object,
    },

    computed: {
      warehouseWarehouseAddress() {
        if(this._.has(this.NP.data.warehouseAddress, 'sender_full_address') &&
            this.NP.data.typeSenderWarehouse === this.NP.data.typesSenderWarehouse.warehouse){
          return this.NP.data.warehouseAddress.sender_full_address
        }

        if(this._.has(this.NP.data.warehouseAddress, 'city') &&
            this.NP.data.typeSenderWarehouse === this.NP.data.typesSenderWarehouse.warehouseNovaPoshta){
          return `${this.NP.data.warehouseAddress.city['Present']} ${this.NP.data.warehouseAddress.warehouse['Description']}`
        }

        return ''
      },
    },

    data(){
      return {
        addressPopup: false,
        addressControlPopup: false,

        isModalCustomLimitsPopup: false,


        serverError: false,
      }
    },

    methods: {
      showCustomLimitsPopup() {
        this.isModalCustomLimitsPopup= true
      },

      closeCustomLimitsPopup() {
        this.isModalCustomLimitsPopup= false
      },

      changeRadio(val){
        this.radio = val
      },

      addressPopupChange(val){
        this.addressPopup = val
      },

      addressControlPopupChange(val){
        this.addressControlPopup = val
      },

      applyWarehouseWarehouseAddress({selected, typeSenderWarehouse}) {
        this.NP.setWarehouseAddress(selected)
        this.NP.setTypeSenderWarehouse(typeSenderWarehouse)
        this.addressPopupChange(false)
      },

      applySenderWarehouseAddress({selected}) {
        this.NP.setSenderAddress(selected)
        this.addressPopupChange(false)
      },

      savedAddressControlPopup(val) {
        this.NP.setSenderAddress(val)
        this.addressControlPopupChange(false)
      },

    },
  }
</script>

<style scoped>

</style>
