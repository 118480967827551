<template>
  <div class="fragment">
    <NovaPoshtaTableUser
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        @resetFilter="resetFilter"
        @changeFilter="changeFilter"
        @showMore="showMore"
        @reload="reload"
    />
  </div>
</template>

<script>
  import NovaPoshtaTableUser from "./NovaPoshtaTableUser/NovaPoshtaTableUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {NOVAPOSHTA_DELIVERY_SERVICE} from "../../../../../staticData/staticVariables";

  export default {
    name: "NovaPoshtaTable",

    components: {
      NovaPoshtaTableUser,
    },

    mixins: [routeFilter],

    watch: {
      isCurrentUserRoleUser: function() {
        this.$router.push(this.$store.getters.GET_PATHS.dashboard)
      }
    },

    data() {
      return {
        forPage: this.$store.getters.getOrdersInternalForPage,
        page: 1,
        onePage: 1,

        filterDate: [],
        filterUserName: '',
        filterUserId: '',
        filterId: '',
        filterPayPalTransaction: '',
        filterShipping: '',
        filterPackageType: '',
        filterTrackingNumber: '',
        filterItemName: '',
        filterShipped: '',
        filterDeclaredValue: '',
        filterDeliveryPrice: '',
        filterPlacesCount: '',

        countFilterParams: 0,
        filterGetParams: {},


      }
    },

    mounted() {
      if(this.isCurrentUserRoleUser){
        this.$router.push(this.$store.getters.GET_PATHS.dashboard)
      }
      this.filter()
    },

    beforeDestroy() {
      this.$store.commit('setOrdersInternal',{ordersInternal: []})
      this.$store.commit('setOrdersInternalCommonList',{ordersInternalCommonList: []})
    },

    methods: {
      getFilterParam() {

        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,

          id: this.$route.query.id,
          payPalTransaction: this.$route.query.payPalTransaction,
          shipping: this.$route.query.shipping,
          packageType: this.$route.query.packageType,
          trackingNumber: this.$route.query.trackingNumber,
          itemName: this.$route.query.itemName,
          shipped: this.$route.query.shipped,

          declaredValue: this.$route.query.declaredValue,
          deliveryPrice: this.$route.query.deliveryPrice,
          placesCount: this.$route.query.placesCount,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextOrdersInternalPage', true)
        this.$store.dispatch('fetchOrdersInternal', url).then(() => {
          this.$store.commit('setNextOrdersInternalPage', false)
        })


        this.checkCountFilter(['user'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})


        this.generateFilterQueryParams(
            myQuery,
            {
              filterId: 'ointId',
              filterUserId: 'ointUserId',

              // filterPayPalTransaction: 'q',
              // filterShipping: 'q',
              // filterPackageType: 'q',
              filterTrackingNumber: 'ointTrackingNumber',
              filterItemName: 'ointDescription',
              filterShipped: 'ointBetweenShippedAt',
              filterDeclaredValue: 'ointDeclaredValue',
              filterDeliveryPrice: 'ointDeliveryPrice',
              filterPlacesCount: 'ointPlacesCount',
            },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
              'ointBetweenCreatedAt',
              date
          )
        }

        if (this.filterShipped.length > 0) {
          let date = this.generateDateForFilter(this.filterShipped, 'YYYY-MM-DD')

          myQuery.whereIn(
              'ointBetweenShippedAt',
              date
          )
        }

        myQuery.where('ointDeliveryServiceId', NOVAPOSHTA_DELIVERY_SERVICE.id)

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    }
  }
</script>

<style scoped>

</style>
